import $ from "jquery";
import http from "@/api/axios";
export default {
    name: "",
    components: {},
    data() {
        return {
            activeCase: false,
            newsList: '',
            paginationParams: {
                limit: 12,
                page: 1
            },
            currentPage: 1,
            total: 0,
            kind: Number,
            loading: false,
            coverName: '新闻动态'
        };
    },
    methods: {
        openCase(mx) {
            this.activeCase = mx;
        },
        alignCenter() {
            const w = document.body.clientWidth;
            const boxes = $(".cover-name")[0];
            if (boxes) {
                const boxWid = boxes.scrollWidth; //获取div的宽
                boxes.style.left = w / 2 - boxWid / 2 + "px";
            }
        },
        getNews(kind) {
            this.loading = true;
            if (kind === '2') {
                setTimeout(() => {
                    this.newsList = [{
                        activated: true,
                        author: "步速者",
                        contentType: 1,
                        coverPlan: "http://imgv4.slimhand.com/FjrJ6_yWD4UT_8W4muWIBOFA80D6.jpg?imageView2",
                        createdTime: "2021-01-12 09:44:07",
                        id: "annual2021",
                        mainBody: "",
                        newsDesc: "《步速者》2021年刊",
                        newsLink: "",
                        newsTime: "2021-01-28 00:00:00",
                        type: "annual"
                            // }, {
                            //     activated: true,
                            //     author: "步速者",
                            //     contentType: 1,
                            //     coverPlan: "http://imgv4.slimhand.com/FjH_keZSX7OajoOqbAR6nGu_URVA.png?imageView2",
                            //     createdTime: "2022-03-01 00:00:00",
                            //     id: "annual2022",
                            //     mainBody: "",
                            //     newsDesc: "《步速者》2022年刊",
                            //     newsLink: "",
                            //     newsTime: "2022-03-01 00:00:00",
                            //     type: "annual"
                    }];
                }, 100);
                this.total = 1;
                this.loading = false;
                this.coverName = '年刊';
                return
            } else if (typeof(kind) !== 'number') {
                http.get("/news/findAllWeb", {
                    params: {
                        ...this.paginationParams,
                        type: kind,
                    }
                }).then((res) => {
                    this.newsList = res.content;
                    this.total = res.totalElements;
                    this.loading = false;
                })
            } else {
                http.get("/news/findAllWeb", {
                    params: {
                        ...this.paginationParams,
                    }
                }).then((res) => {
                    this.newsList = res.content;
                    this.total = res.totalElements;
                    this.loading = false;
                })
            }

        },
        navigateTo(url) {
            if (this.kind === '2') {
                window.open('https://imgv4.slimhand.com' + url + '.pdf');
                return
            } else {
                this.$router.push({
                    path: url
                });
            }

        }
    },
    mounted() {
        const _this = this;
        _this.alignCenter();
        const link = window.location.href;

        let kind = sessionStorage.getItem('kind');
        if (link.indexOf('dynamic') > -1) {
            this.kind = '0';
            this.coverName = '公司动态';
            kind = '0';
        } else if (link.indexOf('monthly') > -1) {
            this.kind = '1';
            this.coverName = '月刊';
            kind = '1';
        } else if (link.indexOf('annual') > -1) {
            this.kind = '2';
            this.coverName = '年刊';
            kind = '2';
        }
        this.kind = kind;
        _this.getNews(kind);

        window.onresize = function() {
            _this.alignCenter();
        };
    },
    computed: {},
    watch: {
        $route() {
            let kind = sessionStorage.getItem('kind');
            const link = window.location.href;
            if (link.indexOf('dynamic') > -1) {
                this.kind = '0';
                this.coverName = '公司动态';
                kind = '0';
            } else if (link.indexOf('monthly') > -1) {
                this.kind = '1';
                this.coverName = '月刊';
                kind = '1';
            } else if (link.indexOf('annual') > -1) {
                this.kind = '2';
                this.coverName = '年刊';
                kind = '2';
            }
            this.kind = kind;
            this.getNews(kind);
            this.paginationParams.page = 1;
        }
    }
};